body {
  font-family: 'Inter, Arial, sans-serif';
}
textarea {
  font-family: 'Arial';
}
.prop-action-btn {
  border: 1px solid #d4d4de;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px 0px;
  color: black;
}

.prop-action-btn:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
  background-color: rgb(240, 250, 255);
  border: 2px solid #007bff;
  color: #007bff;
}

.nav-link.active {
  border-bottom: 3px solid rgba(64, 123, 255, 0.78);
}

.nav-signup-btn {
  margin-top: 15px;
  width: 100%;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.state-input::placeholder {
  text-transform: none;
}

body.modal-open {
  overflow: visible !important;
  padding-right: 0 !important;
}

@media screen and (min-width: 768px) {
  .nav-signup-btn {
    margin-left: 50px !important;
    margin-right: 0;
    margin-top: 0;
    width: 170px;
  }
}

@media screen and (max-width: 768px) {
  .feature-step-text {
    text-align: center;
    margin-top: 12px !important;
    margin-left: auto;
    margin-right: auto;
  }
  .security-item-text {
    max-width: 200px;
  }
}

.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  border: 1px solid #bababa;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.fade-in {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}

.marble-copyright {
  text-align: center;
  margin-top: 2rem;
  font-family: 'Inter';
  font-size: 11px;
  color: #586069;
  margin-top: 1.5rem;
}

.marble-disclaimer {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1400px;
  margin: 0 auto;
  font-family: 'Inter';
  font-size: 10px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
